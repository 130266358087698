const NioPlusIcon = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8 3.33334V12.6667" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.33325 8H12.6666" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default NioPlusIcon

const HamburgerIcon = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 8H2V8.96H22V8ZM22 14H2V14.96H22V14Z"
        fill="black"
      />
    </svg>
  )
}

export default HamburgerIcon

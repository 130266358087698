import MintNioIcon from "@/components/shared/icons/MintNioIcon"
import NioFooterIcon from "@/components/shared/icons/NioFooterIcon"
import { HomePageContainer } from "@/components/shared/shared"
import { FooterLinks } from "@/lib/common/SiteLinks"
import { footerButtonClick } from "@/lib/gtm"
import styled from "@emotion/styled"
import { Box, Typography } from "@mui/material"
import Link from "next/link"

const StyledWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #231f20;
  ${(p) => p.theme.breakpoints.up("md")} {
  }
`

const StyledFooterContainer = styled(HomePageContainer)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  padding: 32px 32px 56px 32px;
  gap: 32px;
  ${(p) => p.theme.breakpoints.up("md")} {
    padding: 96px 100px;
  }
`

const StyledLogoMintContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 52px;
`

const StyledMintStrip = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  padding: 16px 32px;
  display: flex;
  justify-content: center;
  gap: 8px;
  /*  */
`

const LinksFlex = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style-type: none;
  text-align: center;
  padding: 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 32px;
    flex-direction: row;
  }
`

const LinkLi = styled.li``

const Footer = (): React.ReactElement => {
  return (
    <>
      <StyledWrapper>
        <StyledFooterContainer>
          <NioFooterIcon />
          <LinksFlex>
            {FooterLinks.map((el, i) => (
              <LinkLi key={i}>
                <Link
                  href={el.link}
                  style={{ textDecoration: "none" }}
                  onClick={() => {
                    footerButtonClick(el.title)
                  }}
                >
                  <Typography variant="body2" color={"white"}>
                    {el.title}
                  </Typography>
                </Link>
              </LinkLi>
            ))}
          </LinksFlex>
        </StyledFooterContainer>
        <StyledMintStrip>
          <Link href="https://mintapp.co.il" target="_blank">
            <StyledLogoMintContainer>
              <MintNioIcon />
            </StyledLogoMintContainer>
          </Link>
        </StyledMintStrip>
      </StyledWrapper>
    </>
  )
}

export default Footer

import styled from "@emotion/styled"
import { Box, colors, Container, Typography } from "@mui/material"

export const HEADER_HEIGHT_M = 60
export const HEADER_HEIGHT_D = 80
export const HEADER_ANIME_M = 60
export const HEADER_ANIME_D = 80

/*  grid and flex box */
export const StyledGridBox = styled(Box)<{
  $notCenter?: boolean
  $mb?: string
  $rowGap?: string
}>`
  margin-bottom: ${(p) => (p.$mb ? p.$mb : "0")};
  display: grid;
  row-gap: ${(p) => p.$rowGap};
  box-sizing: border-box;
  place-items: ${(p) => (p.$notCenter ? "none" : "center")};
`
export const StyledRow = styled(Box)<{
  $notCenter?: boolean
  /* margin top */
  $mb?: string
  /* margin bottom */
  $mt?: string
  /* margin left */
  $ml?: string
  /* margin right */
  $mr?: string
  $gap?: string
  $width?: string
  $height?: string
  $fullWidth?: boolean
  $justify?: string
  /* horizontal padding */
  $ph?: string
  /* vertical padding */
  $pv?: string
  $wrap?: boolean
}>`
  margin: 0px;
  margin-bottom: ${(p) => (p.$mb ? p.$mb : "0")};
  margin-top: ${(p) => (p.$mt ? p.$mt : "0")};
  margin-left: ${(p) => (p.$ml ? p.$ml : "0")};
  margin-right: ${(p) => (p.$mr ? p.$mr : "0")};
  display: flex;
  flex-direction: row;
  gap: ${(p) => p.$gap};
  box-sizing: border-box;
  place-items: ${(p) => (p.$notCenter ? "none" : "center")};
  width: ${(p) => (p.$fullWidth ? "100%" : "max-content")};
  width: ${(p) => p.$width};
  height: ${(p) => p.$height};
  justify-content: ${(p) => (p.$justify ? p.$justify : "initial")};
  padding-left: ${(p) => (p.$ph ? p.$ph : "0px")};
  padding-right: ${(p) => (p.$ph ? p.$ph : "0px")};
  padding-top: ${(p) => (p.$pv ? p.$pv : "0px")};
  padding-bottom: ${(p) => (p.$pv ? p.$pv : "0px")};
  flex-wrap: ${(p) => (p.$wrap ? "wrap" : "")};
`

export const StyledCol = styled(Box)<{
  $notCenter?: boolean
  $mb?: string
  $mt?: string
  $ml?: string
  $mr?: string
  $gap?: string
  $width?: string
  $height?: string
  $fullWidth?: boolean
  $justify?: string
  $pl?: string
  $pr?: string
  $pb?: string
}>`
  margin: 0px;
  margin-bottom: ${(p) => (p.$mb ? p.$mb : "0")};
  margin-top: ${(p) => (p.$mt ? p.$mt : "0")};
  margin-left: ${(p) => (p.$ml ? p.$ml : "0")};
  margin-right: ${(p) => (p.$mr ? p.$mr : "0")};
  padding-left: ${(p) => (p.$pl ? p.$pl : "0")};
  padding-right: ${(p) => (p.$pr ? p.$pr : "0")};
  padding-bottom: ${(p) => (p.$pb ? p.$pb : "0")};
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.$gap};
  box-sizing: border-box;
  place-items: ${(p) => (p.$notCenter ? "none" : "center")};
  width: ${(p) => (p.$fullWidth ? "100%" : "max-content")};
  width: ${(p) => p.$width};
  height: ${(p) => p.$height};
  justify-content: ${(p) => (p.$justify ? p.$justify : "initial")};
`

export const StyledClassicGrid = styled.div<{
  $gap?: string
  $mb?: string
}>`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: ${(p) => p.$gap};
  margin-bottom: ${(p) => (p.$mb ? p.$mb : "0")};
`
export const StyledMarginDiv = styled.div<{ $mb?: string; $mt?: string }>`
  margin-bottom: ${(p) => (p.$mb ? p.$mb : "0px")};
  margin-top: ${(p) => (p.$mt ? p.$mt : "0px")};
`

/* Pages Wrappers */
export const StyledPageWrapper = styled.div<{
  $padding?: string
  $paddingTop?: string
  $background?: string
}>`
  display: flex;
  justify-content: center;
  padding-top: ${(p) => (p.$paddingTop ? p.$paddingTop : "56px")};
  padding-bottom: ${(p) => (p.$padding ? `${p.$padding}` : "60px")};
  ${(p) => p.theme.breakpoints.up("md")} {
    padding-top: ${(p) => (p.$paddingTop ? p.$paddingTop : "112px")};
    padding-bottom: ${(p) => (p.$padding ? `${p.$padding}` : "100px")};
  }
  background: ${(p) => p.$background && p.$background};
`

/*  Typography */
export const TypographyRtl = styled(Typography)<{
  $color?: string
  $fontSize?: string
  $fontWeight?: string
  $mb?: string
  $mt?: string
  $bold?: boolean
  $light?: boolean
  $small?: boolean
  $mobileCenter?: boolean
}>`
  text-align: right;
  color: ${(p) => (p.$color ? p.$color : "")};
  font-size: ${(p) => p.$small && "14px"};
  font-size: ${(p) => (p.$fontSize ? p.$fontSize : "")};
  font-weight: ${(p) => p.$light && "300"};
  font-weight: ${(p) => p.$bold && "bold"};
  font-weight: ${(p) => (p.$fontWeight ? p.$fontWeight : "")};
  margin-bottom: ${(p) => (p.$mb ? p.$mb : "0px")};
  margin-top: ${(p) => (p.$mt ? p.$mt : "0px")};
  ${(p) => p.theme.breakpoints.down("sm")} {
    text-align: ${(p) => (p.$mobileCenter ? "center" : "right")};
  }
`

export const TypographyCenter = styled(Typography)<{
  $color?: string
  $fontSize?: string
  $fontWeight?: string
  $mb?: string
  $mt?: string
  $bold?: boolean
  $light?: boolean
  $small?: boolean
  $mobileRtl?: boolean
}>`
  text-align: center;
  color: ${(p) => (p.$color ? p.$color : "")};
  font-size: ${(p) => p.$small && "14px"};
  font-size: ${(p) => (p.$fontSize ? p.$fontSize : "")};
  font-weight: ${(p) => (p.$fontWeight ? p.$fontWeight : "")};
  font-weight: ${(p) => p.$light && "300"};
  font-weight: ${(p) => p.$bold && "bold"};
  margin-bottom: ${(p) => (p.$mb ? p.$mb : "0px")};
  margin-top: ${(p) => (p.$mt ? p.$mt : "0px")};
  ${(p) => p.theme.breakpoints.down("sm")} {
    text-align: ${(p) => (p.$mobileRtl ? "right" : "center")};
  }
`

/*  General */

export const HomePageContainer = styled(Container)`
  height: 100%;
  max-width: none;
  padding-right: 20px;
  padding-left: 20px;
  ${(p) => p.theme.breakpoints.up("md")} {
    padding-right: 40px;
    padding-left: 40px;
    max-width: calc(1920px - 100px - 100px);
  }
`
export const StyledClickableDiv = styled.div`
  cursor: pointer;
`
export const StyledSvgIcon = styled.img<{ w?: number; h?: number }>`
  width: ${(p) => (p.w ? `${p.w}px` : "25px")};
  height: ${(p) => (p.h ? `${p.h}px` : "25px")};
`

export const StyledDividerH = styled.div<{
  $color?: string
  $width?: number
  $radius?: number
  $mb?: string
  $mt?: string
  $ml?: string
  $mr?: string
}>`
  background-color: ${(p) => (p.$color ? p.$color : colors.grey)};
  width: 100%;
  height: ${(p) => (p.$width ? `${p.$width}px` : "1px")};
  border-radius: ${(p) => (p.$radius ? `${p.$radius}px` : "1px")};
  margin-bottom: ${(p) => (p.$mb ? p.$mb : "0px")};
  margin-top: ${(p) => (p.$mt ? p.$mt : "0px")};
  margin-left: ${(p) => (p.$ml ? p.$ml : "0px")};
  margin-right: ${(p) => (p.$mr ? p.$mr : "0px")};
`
export const StyledDividerV = styled.div<{
  $color: string
  $width?: number
  $radius?: number
  $mb?: string
  $mt?: string
  $ml?: string
  $mr?: string
}>`
  background-color: ${(p) => (p.$color ? p.$color : colors.grey)};
  width: ${(p) => (p.$width ? `${p.$width}px` : "1px")};
  height: 100%;
  border-radius: ${(p) => (p.$radius ? `${p.$radius}px` : "1px")};
  margin-bottom: ${(p) => (p.$mb ? p.$mb : "0px")};
  margin-top: ${(p) => (p.$mt ? p.$mt : "0px")};
  margin-left: ${(p) => (p.$ml ? p.$ml : "0px")};
  margin-right: ${(p) => (p.$mr ? p.$mr : "0px")};
`

export const StyledSpacer = styled.div<{
  $w?: number
  $h?: number
  $mb?: string
  $mt?: string
  $ml?: string
  $mr?: string
}>`
  width: ${(p) => (p.$w ? `${p.$w}px` : "100%")};
  height: ${(p) => (p.$h ? `${p.$h}px` : "100%")};
  margin-bottom: ${(p) => (p.$mb ? p.$mb : "0px")};
  margin-top: ${(p) => (p.$mt ? p.$mt : "0px")};
  margin-left: ${(p) => (p.$ml ? p.$ml : "0px")};
  margin-right: ${(p) => (p.$mr ? p.$mr : "0px")};
`

"use client"

import { Brand } from "@/api/generated/BMWInterfaces"
import { Desktop } from "@/lib/common/MediaQueries"
import styled from "@emotion/styled"
import { Button } from "@mui/material"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { usePathname } from "next/navigation"
import { Suspense, useRef } from "react"
import Analytics from "../shared/Analytics"
import FooterSocialLinksList from "./Footer/Footer"
import Header from "./Header/Header"

const StyledMain = styled.main<{ isOrder: boolean }>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
  padding-top: 60px;
  height: ${(props) => (props.isOrder ? "100%" : "auto")};
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 80px;
  }
  :focus {
    outline: 0;
  }
`

const StyledButton = styled(Button)`
  position: fixed;
  z-index: -10;
  background-color: black;
  color: white;
  top: -100px;
  &:focus-visible {
    z-index: 9999999;
    top: 0px;
  }
`
const queryClient = new QueryClient()

export default function Layout({
  children,
  brands,
}: {
  children: React.ReactNode
  brands: Brand[]
}): React.ReactElement {
  const pathname = usePathname()
  const isOrder = pathname.includes("/order")
  const mainRef = useRef<HTMLDivElement>(null)
  const handleClick = (): void => {
    if (mainRef.current) {
      mainRef.current.focus()
    }
  }
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense>
        <Analytics />
      </Suspense>
      <Desktop>
        <StyledButton id="skip-to-content" onClick={handleClick} tabIndex={2}>
          דלג לתוכן המרכזי
        </StyledButton>
      </Desktop>
      <Header brands={brands} />
      <StyledMain id="mainContent" tabIndex={-1} ref={mainRef} isOrder={isOrder}>
        {children}
      </StyledMain>

      {!isOrder && <FooterSocialLinksList />}
    </QueryClientProvider>
  )
}

import colors from "@/lib/theme/colors"

const XIcon = ({
  color = colors.borderGrey,
  ...props
}: React.SVGProps<SVGSVGElement>): JSX.Element => {
  const w = 32
  const h = 32
  return (
    <svg
      viewBox={`0 0 ${w} ${h}`}
      width={w}
      height={h}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25 7.41016L7 25.4102"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M25 25.4102L7 7.41016"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default XIcon

"use client"
import { CssBaseline, StyledEngineProvider } from "@mui/material"
import { theme } from "./theme"

import { ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material/styles"

import React from "react"

export const rtlTheme = createTheme({ ...theme, direction: "rtl" })

const ThemeProviders = ({
  children,
}: {
  children: React.ReactNode
  locale: string
}): JSX.Element => {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={rtlTheme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  )
}

export default ThemeProviders
